(function($){

    $(document).ready(function(){

        $('.field-gallery').each(function (i, el){

            let $el = $( el );
            let options = {};

            $el.data('gallery-instance', new Gallery( $el, options ));

        });

    });


    function Gallery( $element ){

        let gallery = this;

        console.log( $element );

        let images = [];
        let $input = $element.find('.field-value');
        let $btnAdd = $element.find('.btn-image-add');
        let $btnDeleteAll = $element.find('.btn-gallery-clear');
        let $gallery = $element.find('.gallery');
        let $reorderModal = $element.find('.modal');


        function thumbTemplate( image ){
            let $img = $('<img>').attr({
                class: "img-thumbnail",
                src: image, // imageUrl( image, {crop: [ 140, 140 ]} ) TODO: This shouls object to determine icon, preview & thumb.
            });

            let $btn = $('<a class="remove-image" href="#"><i class="fas fa-times"></i></a>');

            return $('<div class="image" />').append($img).append($btn);
        }


        this.updateValue = function(){
            console.log( images );
            $input.val( JSON.stringify( images ) );
        };

        this.addImage = function( image ){
            images.push( image );
            $gallery.append( thumbTemplate( image ) );
            gallery.updateValue();
        };

        this.removeImage = function(index){
            $gallery.find('.image').eq( index ).remove();
            if( typeof images[ index ] !== 'undefined' ){
                images.splice( index, 1 );
            }
            gallery.updateValue();
        };

        this.removeAllImages = function(){
            $gallery.find('.image').remove();
            images.length = 0;
            gallery.updateValue();
        };


        $gallery.on('click', '.remove-image', function(e){
            e.preventDefault();

            if( !confirm('Are sure to delete?') ) return;

            let $btn = $(this);
            gallery.removeImage( $btn.parent().index() );
        });


        $btnAdd.click(function(e){
            e.preventDefault();
            MediaGallery.open(function( images ){
                if( typeof images === 'undefined' || images.length === 0 ) return;
                images.forEach(function( image ){
                    gallery.addImage( image.url() );
                });
            }, { select: 'multiple' });
        });

        $btnDeleteAll.click( function (e){
            e.preventDefault();
            if( !confirm('Are sure to delete all images?') ) return;
            gallery.removeAllImages();
        });


        $element.data('images').forEach(function ( image ) {
            gallery.addImage( image );
        })

    }


})(jQuery);
