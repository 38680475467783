(function($){

    let placeholderImage = 'https://via.placeholder.com/300x100.jpg/fff/aaa?text=No Image Selected';

    $('.field-image').each(function(){

        let $field = $(this);
        let $input = $field.find( '.field-value' );
        let $image = $field.find('.image img');


        if( !$input.val().length ){
            $image.attr('src', placeholderImage);
        }

        $field.find('.btn-select-image').click(function(){
            MediaGallery.open(function( selection ){
                if( typeof selection !== 'undefined' && selection.length){
                    let image = selection.pop();
                    $input.val(image.url());
                    $image.attr('src', image.preview);
                    $field.addClass('has-image');
                }
            });
        });

        $field.find('.remove-image').click(function(e){
            e.preventDefault();
            $field.find('.field-value').val('');
            $image.attr('src', placeholderImage);
            $field.removeClass('has-image');
        });
    });

})(jQuery);
