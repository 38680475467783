$(document).ready(function (){


    $("#zip_modal").on('show.bs.modal', function(event){
        // Get button that triggered the modal
        const button = $(event.relatedTarget);
        // Extract value from data-* attributes
        const valueData = button.data('zip-info');

        if(valueData === ''){

            $(this).find('.modal-title').html('Create Zip Code');

            $(this).find('#zip_code').val('');
            $(this).find('#city_id').val('');
            $(this).find('#latitude').val('');
            $(this).find('#longitude').val('');
        }else {

            $(this).find('.modal-title').html('Edit Zip Code');

            $(this).find('#zip_code').val(valueData.zip_code);
            $(this).find('#city_id').val(valueData.city_id);
            $(this).find('#latitude').val(valueData.latitude);
            $(this).find('#longitude').val(valueData.longitude);
        }

    });

});