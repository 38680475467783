(function( $ ){

    $(document).ready(function(){

        $('.info-box .btn-add-new').click(function(e){
            e.stopPropagation();
            e.preventDefault();
            let url = $(this).data('url');
            if( typeof url !== 'undefined' && url.length > 0 ){
                window.location = $(this).data('url');
            }
        });

    });

})(jQuery);
