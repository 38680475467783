// ====================================================
// ===== Application Dependencies & Libraries =========
// ====================================================

require('./bootstrap');


$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});



// =================================
// ===== Utility Functions =========
// =================================

require('../common/utils/confirm');
require('../common/utils/action-delete');
require('../common/utils/number-format');
require('../common/utils/image-url');
require('../common/utils/prop-enable-disable');
require('../common/utils/loading');



// ================================
// ===== Common Components ========
// ================================

require('../common/components/select2');
// require('../common/components/daterange');
require('../common/components/bootstrap-custom-file-filename');



// ================================
// ==== Application Components ====
// ================================

require('./components/media-gallery');
require('./components/fields/all');
require('./components/modal');
require('./components/zone-importer');
require('./components/info-box');
require('./components/charts');
// require('./components/product-mark-as');
// require('./components/sidebar');
// require('./components/css-editor');



// Load CSS editor scripts only if it is required.
// if( $('#salesChart').length ) require('./components/charts');


$(document).ready(() => {

    // If the laraberg textarea don't exists in current page, just return.
    if( !$('#laraberg-editor').length ) return;

    // Initialize Laraberg Editor
    Laraberg.init('laraberg-editor', {
        // sidebar: true
    });
});
