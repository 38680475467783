class ZoneImporter{


    constructor(el){
        this.$el = $(el);
        this.$el.data('zone-importer', this);
        this.id = 'form-target-' + Math.round(Math.random() * 100000000);
        this.$formTarget = $('<iframe style="display: none"></iframe>');
        this.$formTarget.attr({
            id: this.id,
            name: this.id,
        }).appendTo('body');
        this.$form = this.$el.find('.form-zone-importer');
        this.$form.append($('<input type="hidden" name="form_target">').val(this.id));
        this.$form.attr('target', this.id);
        this.events();
    }


    events(){
        this.$form.submit(() => {
            this.busy();
            this.message('warning', 'Uploading...');
        })
    }


    busy(isBusy){

        isBusy = ( typeof isBusy === 'undefined' ) ? true : isBusy;
        const $inputs = this.$form.find('input,button,textarea,select');

        if(isBusy){
            setTimeout(() => {
                $inputs.prop_disable();
            }, 10);
            this.$form
                .removeClass('form-state-complete')
                .addClass('form-state-loading');
        }else{
            $inputs.prop_enable();
            this.$form
                .removeClass('form-state-loading')
                .addClass('form-state-complete');
        }
    }


    message(color, message){
        this.$el.find('.response')
            .attr('class', 'response')
            .addClass('text-' + color)
            .html(message)
    }


    resetForm(){
        this.$form.find('input[type=file]')
            .val('')
            .change();
    }


    static response(res){

        if(typeof res.form_target === 'undefined') return;

        /**
         * @type {ZoneImporter}
         */
        const zoneImporter = $('form[target="' + res.form_target + '"]')
            .parents('.zone-importer')
            .data('zone-importer');

        let color = 'danger';

        if(res.status === 'success'){
            color = 'success';
            zoneImporter.resetForm();
        }

        zoneImporter.busy(false);
        zoneImporter.message(color, res.data.message);
    }

}


if( typeof window.ZoneImporter === 'undefined' ){
    $(document).ready(() => {
        $('.zone-importer').each((i, e) => {
            new ZoneImporter($(e));
        });
    });
}


module.exports = window.ZoneImporter = ZoneImporter;
