$(document).ready(function () {

    $('.wysiwyg-editor').summernote({
        height:200,
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'mediaBrowser', 'video']],
            ['view', ['fullscreen', 'codeview', 'help']],
        ],
        buttons: {
            mediaBrowser: MediaBrowserButton
        },
        callbacks: {
            onInit: (context) => setTimeout(() => { autoHeight(context.editable) }, 10),
            onChange: (contents, $editable) => autoHeight($editable)
        }
    });

});


/**
 * Resizes an editor based on content and sets the height to that of its contents.
 * @param $editor
 */
function autoHeight($editor){
    let totalHeight = 0;
    const $elements = $editor.children();
    $elements.each((i, e) => {
        const $el = $(e);
        totalHeight += $el.outerHeight() + parseFloat($el.css('margin-top')) + parseFloat($el.css('margin-bottom'));
        if(i === $elements.length - 1){
            const height = Math.max(300, totalHeight + 20);
            console.log(height);
            $editor.height(height);
        }
    });
}


/**
 * Custom Button for Media Gallery.
 */
function MediaBrowserButton(/*context*/){
    const ui = $.summernote.ui;

    // console.log(context);

    // create button
    const button = ui.button({
        contents: '<i class="note-icon-picture"/>',
        tooltip: 'Image',
        click: function () {

            const context = $(this)
                .parents('.note-editor')
                .siblings('.wysiwyg-editor')
                .data('summernote');

            MediaGallery.open(function( selection ){
                if( typeof selection !== 'undefined' && selection.length){
                    let image = selection.pop();
                    const imageUrl = image.url({resize: 1200});
                    context.invoke('insertImage', imageUrl, function ($image) {

                        const srcset = [
                            image.url({resize: 400}) + " 400w",
                            image.url({resize: 600}) + " 600w",
                            image.url({resize: 800}) + " 800w",
                            image.url({resize: 1000}) + " 1000w",
                        ];

                        $image
                            .addClass('overflow-image')
                            .attr("srcset", srcset.join(', '));
                        
                    });
                }
            });

        }
    });

    return button.render();
}
